<template>
  <div>
    <b-loading :active="loadingUserInfo" :is-full-page="false"></b-loading>
    <div v-if="enabled">
      <div class="block has-text-centered">
        <b-button type="is-primary" native-type="button" @click="openModal">{{
          selectedUser
            ? selectedUser["sn_to_display"]
            : $t("policies.template.buttons.select_signer")
        }}</b-button>
      </div>
      <!-- <span v-if="selectedUser">{{ selectedUser["sn_to_display"] }}</span> -->
      <div class="block">
        <div
          v-for="(info, key) in filteredUserInfo"
          :key="key"
          class="has-text-centered"
        >
          <p>{{ $t(`policies.${key}`) }}:</p>
          <p class="tag is-medium is-primary is-light">{{ info }}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <b-button type="is-primary" disabled native-type="button"
        >Non presente</b-button
      >
    </div>

    <b-modal v-model="showModal" has-modal-card>
      <div class="modal-card">
        <section class="modal-card-body">
          <b-table
            :data="users"
            :loading="loadingTemplates"
            :selected.sync="selectedUser"
            focusable
            paginated
            backend-pagination
            :total="totalResults"
            :per-page="perPage"
            @page-change="onPageChange"
          >
            <template v-for="(column, index) in userCols">
              <b-table-column :key="index" v-bind="column">
                <template v-if="column.searchable" #searchable="props">
                  <b-input
                    v-model="userTablefilters[props.column.field]"
                    :placeholder="$t('search')"
                    icon="magnify"
                  />
                </template>
                <template v-slot="props">
                  {{ props.row[column.field] }}
                </template>
              </b-table-column>
            </template>
          </b-table>
        </section>
        <footer class="modal-card-foot h-centered">
          <button class="button" type="button" @click="showModal = false">
            <b-icon icon="close"></b-icon>
            <span>{{ $t("buttons.close") }}</span>
          </button>
          <button
            class="button is-primary"
            type="button"
            :disabled="!Boolean(selectedUser)"
            @click="onUserConfirmed"
          >
            <b-icon icon="download"></b-icon>
            <span>{{ $t("buttons.import") }}</span>
          </button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { policiesService } from "@/services";
export default {
  props: {
    companyName: {
      type: String,
      required: true,
    },
    role: {
      type: Object,
      required: true,
    },
    merchantUniqueName: {
      type: String,
      required: true,
    },
    enabled: {
      required: false,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
      page: 1,
      perPage: 50,
      totalResults: 0,
      selectedUser: null,
      loadingTemplates: false,
      loadingUserInfo: false,
      users: [],
      userCols: [
        {
          field: "sn_to_display",
          label: this.$t("policies.users.label"),
          centered: false,
          searchable: true,
        },
      ],
      userTablefilters: {
        sn_to_display: "",
      },
      userInfo: {},
    };
  },
  computed: {
    roleId() {
      return this.role.id;
    },
    roleName() {
      return this.role.name;
    },
    filteredUserInfo() {
      // const clone = (({ is_pec_address, ...o }) => o)(this.userInfo);
      // const { ["is_pec_address"]: removedKey, ...newObj } = this.userInfo;
      // const filtered = { ...this.userInfo, is_pec_address };
      let filtered = Object.fromEntries(
        Object.entries(this.userInfo).filter(
          ([key]) => key !== "is_pec_address" && key !== "is_approved"
        )
      );
      return filtered;
    },
  },
  watch: {
    userTablefilters: {
      deep: true,
      handler: function () {
        this.onFilter();
      },
    },
  },
  methods: {
    selectSigner() {
      this.loadingTemplates = true;
      policiesService
        .fetchUsersByRole(
          this.companyName,
          this.roleName,
          this.merchantUniqueName,
          this.page,
          this.perPage,
          this.userTablefilters
        )
        .then((res) => {
          this.users = res;
        })
        .finally(() => (this.loadingTemplates = false));
    },
    async getUserInfo() {
      let infos = null;
      try {
        this.loadingUserInfo = true;
        const res = await policiesService.fetchUserInfo(
          this.companyName,
          this.selectedUser["sub_nickname"]
        );
        // this.userInfo = res;
        infos = res;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingUserInfo = false;
      }
      return infos;
      // this.loadingUserInfo = true;
      // policiesService
      //   .fetchUserInfo(this.companyName, this.selectedUser["sub_nickname"])
      //   .then((res) => {
      //     this.userInfo = res;
      //   })
      //   .finally(() => (this.loadingUserInfo = false));
    },
    openModal() {
      this.selectSigner();
      this.showModal = true;
    },
    onPageChange(page) {
      this.page = page;
      this.selectSigner();
    },
    async onUserConfirmed() {
      this.showModal = false;
      const infos = await this.getUserInfo();
      if (infos) {
        if (infos["is_approved"]) {
          this.userInfo = infos;
          this.$emit("user", { ...this.selectedUser });
        } else {
          this.selectedUser = null;
          this.$buefy.toast.open({
            message: this.$t("policies.upload.error.user_not_approved"),
            type: "is-danger",
            duration: 5000,
          });
          this.$emit("not_approved", { infos });
        }
      }
    },
    onFilter: _.debounce(function () {
      this.selectSigner();
    }, 500),
  },
};
</script>

<style></style>
