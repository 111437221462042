<template>
  <div class="has-background-eurotext-light">
    <b-field grouped>
      <!-- Request payment checkbox -->
      <b-checkbox
        v-model="paymentRequired"
        type="is-primary"
        @input="paymentChecked"
        expanded
        @change.native="emitPaymentChange"
      >
        <span class="has-text-light">
          {{ $t("policies.upload.payment.require_payment_label") }}
        </span>
      </b-checkbox>
      <b-field v-show="paymentRequired" grouped>
        <ValidationProvider
          :rules="`${paymentRequired ? 'required' : ''}`"
          v-slot="{ errors }"
          slim
        >
          <!-- Payment amount text box -->
          <b-field :type="{ 'is-danger': errors[0] }" :message="errors">
            <b-input
              v-model="amount"
              type="number"
              step="0.01"
              :placeholder="$t('policies.upload.payment.amount')"
              @change.native="emitPaymentChange"
            ></b-input>
          </b-field>
        </ValidationProvider>
        <ValidationProvider
          :rules="`${paymentRequired ? 'required' : ''}`"
          v-slot="{ errors }"
          slim
          style="max-width: 8em"
        >
          <!-- Payment currency text box -->
          <b-field :type="{ 'is-danger': errors[0] }" :message="errors">
            <b-autocomplete
              v-model="currency"
              :data="currencies"
              :placeholder="$t('policies.upload.payment.currency')"
              open-on-focus
              keep-first
              select-on-click-outside
              icon-right="chevron-down"
              @input="(val) => (currency = val ? val.toUpperCase() : val)"
              @select="onCurrencySelect"
            ></b-autocomplete>
          </b-field>
        </ValidationProvider>

        <!-- Remote merchant selection -->
        <!-- MAYBE: Use b-select instead? -->
        <PayMerchantDropdown
          :payMerchants="paymentMerchants"
          ref="payMerchantDropdown"
        >
        </PayMerchantDropdown>
      </b-field>
    </b-field>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import PayMerchantDropdown from "../PayMerchantDropdown.vue";
export default {
  components: {
    ValidationProvider,
    PayMerchantDropdown,
  },
  props: {
    defaultAmount: {
      type: String,
      default: "",
    },
    defaultCurrency: {
      type: String,
      default: "",
    },
    defaultIsRequired: {
      type: Boolean,
      default: false,
    },
    paymentMerchants: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      paymentRequired: this.defaultIsRequired,
      amount: this.defaultAmount,
      currency: this.defaultCurrency,
      currencies: ["EUR", "USD", "GBP"],
      merchants: this.paymentMerchants,
    };
  },
  computed: {
    filteredCurrencyArray() {
      return this.currencies.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.currency.toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {
    emitPaymentChange() {
      const obj = {
        paymentRequired: this.paymentRequired,
        amount: this.amount,
        currency: this.currency,
      };
      console.log(obj);
      this.$emit("change", obj);
    },
    paymentChecked(val) {
      if (!val) {
        this.currency = "";
        this.amount = "";
      } else {
        this.currency = "EUR";
      }
      // localStorage.setItem("policy_payment_required", val);
    },
    onCurrencySelect(val) {
      this.currency = val ? val : "";
      this.emitPaymentChange();
    },
    getRemoteMerchantValue() {
      return this.$refs.payMerchantDropdown.getValue();
    },
    setRemoteMerchantValue(nickname) {
      this.$refs.payMerchantDropdown.setValue(nickname);
    },
    setDropdownError(isError) {
      this.$refs.payMerchantDropdown.setError(isError);
    },
    setDropdownDisabled(isDisabled) {
      this.$refs.payMerchantDropdown.setDisabled(isDisabled);
    },
  },
  mounted() {},
};
</script>

<style></style>
