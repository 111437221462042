<template>
  <div class="box">
    <!-- <b-modal v-model="showSelectUser" has-modal-card>
      <template-import
        :companyName="companyName"
        :merchantUniqueName="merchantUniqueName"
        @import="importUser($event)"
      ></template-import>
    </b-modal> -->

    <p class="has-text-centered is-size-4">
      {{ $t(`policies.${template.type}.label`) }}
    </p>
    <hr />
    <div class="">
      <p class="block is-size-6 has-text-centered">
        {{ $t("policies.template.name.label") }}:
        <b-tag type="is-primary is-light" size="is-medium">{{
          template.name
        }}</b-tag>
      </p>

      <p class="block is-size-6 has-text-centered">
        <!-- {{ $t("policies.template.name.label") }}: -->
        {{ $t("policies.template.expected_sigers") }}:
        <b-taglist class="is-inline-flex">
          <template v-for="role of signerRoles">
            <b-tag
              :key="`${role.id}_${role.name}`"
              type="is-primary is-light"
              size="is-medium"
              v-if="template['signers_roles'].includes(role.name)"
              >{{ role.description }}</b-tag
            >
          </template>
        </b-taglist>
      </p>

      <!-- <b-field class="file is-primary" :class="{ 'has-name': !!file }">
        <b-upload v-model="file" class="file-label">
          <span class="file-cta">
            <b-icon class="file-icon" icon="upload"></b-icon>
            <span class="file-label">Click to upload</span>
          </span>
          <span class="file-name" v-if="file">
            {{ file.name }}
          </span>
        </b-upload>
      </b-field> -->

      <div class="block is-flex is-justify-content-space-evenly">
        <ValidationProvider
          :vid="template.type"
          :name="$t(`policies.${template.type}.label`)"
          rules="required|ext:pdf|mimes:application/pdf"
          ref="provider"
          v-slot="{ validate, errors }"
        >
          <b-field
            class="file is-centered"
            :message="errors"
            :type="{ 'is-danger': errors[0] }"
          >
            <b-upload accept=".pdf" :value="file" @input="updateValue">
              <a class="button is-primary">
                <b-icon icon="upload"></b-icon>
                <span>{{
                  file ? file.name : $t("policies.template.buttons.select_doc")
                }}</span>
              </a>
            </b-upload>
          </b-field>
        </ValidationProvider>

        <button
          class="button is-primary"
          type="button"
          @click="$emit('preview')"
          :disabled="!file"
        >
          <b-icon icon="open-in-new"></b-icon>
          <span>{{ $t("policies.template.buttons.preview") }}</span>
        </button>
      </div>

      <!-- <table class="table is-bordered is-fullwidth">
        <tbody>
          <tr v-for="role of signerRoles" :key="`${role.id}_${role.name}`">
            <th>{{ role.description }}</th>
            <td>
              <sig-user-selection
                :enabled="template['signers_roles'].includes(role.id)"
                :role="role"
                :merchantUniqueName="merchantUniqueName"
                @user="onUserSelect($event, role)"
              ></sig-user-selection>
            </td>
          </tr>
        </tbody>
      </table> -->
    </div>
  </div>
</template>

<script>
// import SigUserSelection from "./SigUserSelection.vue";
import { ValidationProvider } from "vee-validate";
export default {
  // components: { SigUserSelection },
  components: { ValidationProvider },
  props: {
    signerRoles: {
      type: Array,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
    merchantUniqueName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showSelectUser: false,
      file: null,
    };
  },
  watch: {
    file(newVal) {
      this.onFileModify(newVal);
    },
  },
  methods: {
    async updateValue(newVal) {
      const { valid } = await this.$refs.provider.validate(newVal);
      console.log(valid);

      if (valid) {
        this.file = newVal;
      }
    },
    onFileModify(file) {
      this.$emit("file", file);
    },
    // onUserSelect(user, role) {
    //   this.$emit("user", { user: user, role });
    // },
  },
};
</script>

<style></style>
